


import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import NotificationsMixin from '@/_mixins/notifications.mixin';
import Company from '@/_modules/promo-hall/components/company/company.vue';
import { Action } from 'vuex-class';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import {TFile} from '@/_types/file.type';

@Component({
  components: { Company },
})
export default class CompaniesListItem extends mixins(NotificationsMixin) {

  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number }) => void;

  @Prop(Object) readonly company: TPromoPage;
  @Prop(Boolean) readonly isPreviewEnabled: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly isLast: boolean;

  public get previewImage(): string {
    if (!this.company || !this.company.photos || !this.company.photos.length) {
      return '';
    }

    const firstImage: TFile = this.company.photos.find(photo => photo.url && this.isImage(photo.url));

    return firstImage ? firstImage.url || '' : '';
  }

  public getCompanyPreviewStyle(): { [key: string]: string } {
    const result: { [key: string]: string } = {};

    if (this.previewImage) {
      result['background-image'] = `url('${this.previewImage}')`;
    }

    return result;
  }

  onShowCompany(): void {
    this.$emit('onShowCompany');
  }

  public isImage(filename: string): boolean {
    if (!filename) {
      return false;
    }

    const imageExtensions: string[] = ['jpg', 'png', 'gif', 'jpe', 'jpeg', 'jpeg2000', 'webp', 'bmp'];
    const fileExt: string = filename.split('.').pop().toLowerCase();
    return !!imageExtensions.find(imageExt => fileExt === imageExt);

  }

}
