var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company"},[(_vm.companyType === 'default')?[_c('ew-avatar-company',{attrs:{"company":_vm.company,"width":_vm.avatarSize()}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"primary-text company-name"},[_vm._v(_vm._s(_vm.companyName))]),(_vm.companyGeoInfo)?_c('div',{staticClass:"secondary-text company-location"},[_vm._v(_vm._s(_vm.companyGeoInfo))]):_vm._e(),(_vm.companyDescription)?_c('div',{staticClass:"secondary-text company-description line-clamp-2"},[_vm._v(_vm._s(_vm.companyDescription))]):_vm._e()]),_c('div',{staticClass:"company-actions"},[(!_vm.isCompanyOwner)?_c('div',{staticClass:"action"},[_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.toggleFavorite($event)}}},[(_vm.company.is_favorite)?_c('iconFavorite',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('button.rm_favorites'),
              classes: ['vue-tooltip-dark'],
              placement: 'bottom',
              delay: 0,
            }),expression:"{\n              content: $t('button.rm_favorites'),\n              classes: ['vue-tooltip-dark'],\n              placement: 'bottom',\n              delay: 0,\n            }"}]}):_c('iconUnFavorite',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('button.favorites'),
              classes: ['vue-tooltip-dark'],
              placement: 'bottom',
              delay: 0,
            }),expression:"{\n              content: $t('button.favorites'),\n              classes: ['vue-tooltip-dark'],\n              placement: 'bottom',\n              delay: 0,\n            }"}]})],1)]):_vm._e(),_c('div',{staticClass:"action"},[_c('span',[_c('iconShare',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.$t('button.share'),
                classes: ['vue-tooltip-dark'],
                placement: 'bottom',
                delay: 0,
              }),expression:"{\n                content: $t('button.share'),\n                classes: ['vue-tooltip-dark'],\n                placement: 'bottom',\n                delay: 0,\n              }"}],nativeOn:{"click":function($event){$event.stopPropagation();return _vm.shareCompany($event)}}})],1)])])]:_vm._e(),(_vm.companyType === 'wide')?[_c('ew-avatar-company',{attrs:{"company":_vm.company,"width":_vm.avatarSize()}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"primary-text company-name"},[_vm._v(_vm._s(_vm.companyName))]),(_vm.companyGeoInfo)?_c('div',{staticClass:"secondary-text company-location"},[_vm._v(_vm._s(_vm.companyGeoInfo))]):_vm._e(),(_vm.companyDescription)?_c('div',{staticClass:"secondary-text company-description line-clamp-4"},[_vm._v(_vm._s(_vm.companyDescription))]):_vm._e()])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }