


import {mixins} from 'vue-class-component';
import {Component, Watch} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import PerfectScrollbar from 'perfect-scrollbar';
import {Subject} from 'rxjs';
import ContactsNavigation from '@/_modules/contacts/components/contacts-navigation/contacts-navigation.vue';
import {TContact} from '@/_types/contact.type';
import {TApiListResponse} from '@/_types/api/api-list-response.type';
import {ContactsFilterType, TContactsFilter} from '@/_modules/contacts/types/contacts-filter.type';
import ContactListItem from '@/_modules/promo/components/contact-list-item/contact-list-item.vue';
import NotificationsMixin from '@/_mixins/notifications.mixin';
import {TPromoPage} from '@/_types/promo-page/promo-page.type';
import IconArrowLeft from '@/_modules/icons/components/icon-arrow-left.vue';
import {TEvent} from '@/_types/event.type';
import ModuleHasNoItems from '@/_components/module-has-no-items/module-has-no-items.vue';
import IconContacts from '@/_modules/icons/components/sidebar/icon-contacts.vue';

const CONTACTS_PER_PAGE = 102;

@Component({
  components: {
    ContactsNavigation,
    ContactListItem,
    IconArrowLeft,
    ModuleHasNoItems,
    IconContacts,
  },
})
export default class Contacts extends mixins(NotificationsMixin) {

  @Getter('_eventStore/event') public readonly event: TEvent;
  @Getter('promoStore/promoPages') public readonly promoPages: TPromoPage[];
  @Getter('promoStore/promoPageListLoading') public readonly isPromoPagesLoading: boolean;
  @Getter('promoPageStore/contact') public readonly myself: TContact;
  @Getter('notificationsStore/messagesCount') public readonly messagesCount: number;

  public isAbleToViewContactList: boolean = true;
  public contacts: TContact[] = [];
  public isContactsLoading: boolean = false;
  public isContactsEverLoading: boolean = false;

  private contactsIds: { [contactId: number]: boolean } = {};
  private bottomOfTheListObserver: IntersectionObserver;
  private lastBottomIntersectionResult: IntersectionObserverEntry = null;
  private destroyed$: Subject<void> = new Subject<void>();
  private isMyselfExcluded: boolean = false;

  public mounted(): void {
    this.subscribeToPageEvents();
    this.dispatchCheckPromoPageAccess();
  }

  public beforeDestroy(): void {
    this.destroyBottomOfTheListObserver();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get contactId(): number {
    return (this.$route.params.contact_id && parseInt(this.$route.params.contact_id, 10)) || null;
  }

  public get isLoading(): boolean {
    return this.isContactsLoading || this.isPromoPagesLoading;
  }

  public get filter(): TContactsFilter {
    let tagIds: number[] = [];
    if (typeof this.$route.query.tags === 'string') {
      tagIds = [ parseInt(this.$route.query.tags, 10) ];
    } else if (Array.isArray(this.$route.query.tags)) {
      tagIds = (this.$route.query.tags as string[]).map(item => parseInt(item, 10));
    }

    let type: ContactsFilterType;
    switch (this.$route.query.type) {
      case ContactsFilterType.BOOTH_VIEWERS:
      case ContactsFilterType.MY:
        type = this.$route.query.type;
        break;
      default:
        type = ContactsFilterType.ALL;
    }

    return {
      type: type,
      search: ('' + (this.$route.query.search || '')).trim() || undefined,
      country: ('' + (this.$route.query.country || '')).trim() || undefined,
      tagIds: tagIds,
    };
  }

  public get perfectScrollbarOptions(): PerfectScrollbar.Options {
    return {
      suppressScrollX: true,
      wheelSpeed: 1,
    };
  }

  public get isTryToChangeSearchRequestTextVisible(): boolean {
    return this.$route.query && Object.hasOwnProperty.call(this.$route.query, 'search');
  }

  @Watch('promoPages', { immediate: false })
  private onPromoPagesChange(): void {
    if (!this.promoPages || !this.promoPages.length) {
      return;
    }
    this.checkPromoPageAccess();
  }

  @Watch('myself', { immediate: true })
  private onMyselfChange(): void {
    this.checkPromoPageAccess();
  }

  @Watch('lastBottomIntersectionResult', { immediate: true })
  private onLastBottomIntersectionResultChange(): void {
    this.checkLastBottomIntersectionResult();
  }

  @Watch('filter', { immediate: false })
  private onFilterChange(newValue: TContactsFilter, oldValue: TContactsFilter): void {
    if (
      newValue.type === oldValue.type
      && newValue.search === oldValue.search
      && newValue.country === oldValue.country
      && newValue.tagIds.length === oldValue.tagIds.length
      && newValue.tagIds.every((val, index) => val === oldValue.tagIds[index])
    ) {
      return;
    }

    this.clearPreviousResults();
    this.requestNextPage(true);
  }

  private async dispatchCheckPromoPageAccess(): Promise<void> {
    if (this.filter && this.filter.type === ContactsFilterType.BOOTH_VIEWERS) {
      await this.requestAllPromoPages();
      this.checkPromoPageAccess();
    }
  }

  private clearPreviousResults(): void {
    this.contacts = [];
    this.contactsIds = {};
    this.isMyselfExcluded = false;
    this.createBottomIntersectionObserver();
  }

  private checkLastBottomIntersectionResult(): void {
    if (this.lastBottomIntersectionResult && this.lastBottomIntersectionResult.isIntersecting) {
      this.requestNextPage();
    }
  }

  private async requestAllPromoPages(): Promise<void> {
    await this.$store.dispatch('promoStore/promoPageListAll', {
      event_id: this.eventId,
    });
  }

  private async requestNextPage(force?: boolean): Promise<void> {
    if (this.isContactsLoading && !force) {
      return;
    }
    if (this.filter.type && this.filter.type === ContactsFilterType.BOOTH_VIEWERS && !this.hasListViewAccess) {
      return;
    }
    this.isContactsLoading = true;
    this.isContactsEverLoading = true;
    const filter = this.filter;
    const nextContactsPage: TApiListResponse<TContact> = await this.$store.dispatch('contactsStore/requestContactsListPage', {
      eventId: this.eventId,
      limit: CONTACTS_PER_PAGE,
      offset: this.isMyselfExcluded ? this.contacts.length + 1 : this.contacts.length,
      search: [filter.search, filter.country].filter(x => x).join(' ').trim(),
      tags: filter.tagIds,
      type: filter.type,
      externalId: this.$route.query && this.$route.query.boothExternalId ? this.$route.query.boothExternalId : null,
    });

    if (nextContactsPage) {
      const totalContacts = nextContactsPage.Total;
      let newContactsCounter = 0;
      nextContactsPage.List.forEach(contact => {
        if (!this.contactsIds[contact.id]) {

          if (!this.myself || this.myself.id !== contact.id) {
            this.contacts.push(contact);
          } else {
            this.isMyselfExcluded = true;
          }

          this.contactsIds[contact.id] = true;
          newContactsCounter++;
        } else {
          /* ignore? */
        }
      });

      const isLastPageLoaded = newContactsCounter < 1
        || nextContactsPage.List.length < CONTACTS_PER_PAGE
        || this.contacts.length >= totalContacts;

      if (isLastPageLoaded) {
        this.destroyBottomOfTheListObserver();
      } else {
        this.$nextTick(() => {
          this.checkLastBottomIntersectionResult();
        });
      }
    }

    this.isContactsLoading = false;
  }

  private destroyBottomOfTheListObserver(): void {
    if (!this.bottomOfTheListObserver) {
      return;
    }
    this.bottomOfTheListObserver.disconnect();
    this.bottomOfTheListObserver = null;
    this.lastBottomIntersectionResult = null;
  }

  private subscribeToPageEvents(): void {
    this.createBottomIntersectionObserver();
  }

  private createBottomIntersectionObserver(): void {
    /* intersection observer for lazy-loading */
    this.destroyBottomOfTheListObserver();
    const spacer = this.$refs.spacer as HTMLDivElement;
    if (spacer) {
      this.bottomOfTheListObserver = new IntersectionObserver(this.bottomOfTheListObserverCallback, {
        threshold: 0.5, // we don't need to observe every pixel change
        rootMargin: '1000px', // extend intersection area for earlier triggering
      });
      this.bottomOfTheListObserver.observe(spacer);
    }
  }

  private bottomOfTheListObserverCallback(entries: IntersectionObserverEntry[]): void {
    this.lastBottomIntersectionResult = (entries && entries[0]) || null;
  }

  public get isEventOrganizer(): boolean {
    if (this.event && this.myself && this.myself.user) {
      return this.event.creator_user_id === this.myself.user.id;
    }
    return false;
  }

  private checkPromoPageAccess(): void {
    if (this.isEventOrganizer || this.filter.type !== ContactsFilterType.BOOTH_VIEWERS) {
      this.hasListViewAccess = true;
      return;
    }
    let checkResult = false;
    for (let i = 0; i < this.promoPages.length; i++) {
      const promoPage = this.promoPages[i];
      if (this.myself && promoPage.attached_contacts && promoPage.attached_contacts.find(item => item.contact.id === this.myself.id)) {
        checkResult = true;
        break;
      }
    }
    this.hasListViewAccess = checkResult;
  }

  public get hasListViewAccess(): boolean {
    return this.isAbleToViewContactList;
  }

  public set hasListViewAccess(value: boolean) {
    this.isAbleToViewContactList = value;
  }

}
