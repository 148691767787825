import { render, staticRenderFns } from "./companies-list-item.html?vue&type=template&id=308f525c&scoped=true&"
import script from "./companies-list-item.vue?vue&type=script&lang=ts&"
export * from "./companies-list-item.vue?vue&type=script&lang=ts&"
import style0 from "./companies-list-item.scss?vue&type=style&index=0&id=308f525c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "308f525c",
  null
  
)

export default component.exports