


import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { Location } from 'vue-router';
import { mapGetters } from 'vuex';
import HorizontalMenu from '@/_modules/controls/components/horizontal-menu/horizontal-menu.vue';
import HorizontalMenuItemLink
  from '@/_modules/controls/components/horizontal-menu-item-link/horizontal-menu-item-link.vue';
import IconCloseQrPopup from '@/_modules/icons/components/qrpopup/icon-close-qr-popup.vue';
import { TContactTag } from '@/_types/contact-tag.type';

type TLocalContactTag = {
  tag: TContactTag;
  isSelected: boolean;
};

@Component({
  name: 'contacts-navigation',
  components: {
    HorizontalMenu,
    HorizontalMenuItemLink,
    IconCloseQrPopup,
  },
  computed: {
    ...mapGetters({
      eventContactTags: '_eventStore/eventContactTags',
    }),
  },
})
export default class ContactsNavigation extends Vue {

  public readonly eventContactTags: TContactTag[];

  public isTagsSelectorPopupVisible: boolean = false;
  public eventContactTagsLocal: TLocalContactTag[] = [];

  public get isFilterSearchDisabled(): boolean {
    return this.queryType === 'my';
  }

  public get queryType(): 'all' | 'my' {
    return this.$route.query.type === 'my' ? 'my' : 'all';
  }

  public get querySearch(): string {
    return ('' + (this.$route.query.search || '')).trim();
  }

  public get queryTagIds(): number[] {
    if (typeof this.$route.query.tags === 'string') {
      return [ parseInt(this.$route.query.tags, 10) ];
    } else if (Array.isArray(this.$route.query.tags)) {
      return (this.$route.query.tags as string[]).map(item => parseInt(item, 10));
    }
    return [];
  }

  public get menuLocationTypeAll(): Location {
    return {
      name: this.$route.name,
      params: this.$route.params,
      query: Object.assign({}, this.$route.query, {
        type: undefined,
      }),
    };
  }

  public get menuLocationTypeMy(): Location {
    return {
      name: this.$route.name,
      params: this.$route.params,
      query: Object.assign({}, this.$route.query, {
        type: 'my',
      }),
    };
  }

  public onTagsFilterClick(): void {
    if (this.isFilterSearchDisabled) {
      this.isTagsSelectorPopupVisible = false;
      return;
    }
    this.isTagsSelectorPopupVisible = !this.isTagsSelectorPopupVisible;
  }

  public closeContactTagsSelectorPopup(): void {
    this.isTagsSelectorPopupVisible = false;
  }

  public toggleContactTagSelection(localContactTag: TLocalContactTag): void {
    localContactTag.isSelected = !localContactTag.isSelected;
    const tagIds = this.eventContactTagsLocal
      .filter(item => item.isSelected)
      .map(item => item.tag.id);

    this.$router.push({
      name: this.$route.name,
      params: this.$route.params,
      query: Object.assign({}, this.$route.query, {
        tags: tagIds.length > 0 ? tagIds : undefined,
      }),
    });
  }

  public clearSelectedContactTags(): void {
    this.$router.push({
      name: this.$route.name,
      params: this.$route.params,
      query: Object.assign({}, this.$route.query, {
        tags: undefined,
      }),
    });
    this.initEventContactTagsLocal();
    this.closeContactTagsSelectorPopup();
  }

  public onSearchClick(): void {
    const searchInput = this.$refs.searchInput as HTMLInputElement;
    if (!searchInput || this.isFilterSearchDisabled) {
      return;
    }
    const searchString = (searchInput.value || '').trim();
    if (searchString !== this.querySearch) {
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: Object.assign({}, this.$route.query, {
          search: searchString || undefined,
        }),
      });
    }
  }

  @Watch('eventContactTags', { immediate: true })
  private onEventContactTagsChange(): void {
    this.initEventContactTagsLocal();
  }

  private initEventContactTagsLocal(): void {
    const queryTagIds = this.queryTagIds;
    this.eventContactTagsLocal = (this.eventContactTags || []).map(tag => {
      return {
        tag,
        isSelected: queryTagIds.findIndex(id => id === tag.id) >= 0
      };
    });
  }
}
