


import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import NotificationsMixin from '@/_mixins/notifications.mixin';
import { TOpenEwSharerPayload } from '@/_store/ew-sharer.store';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TPromoPageFavoriteParams } from '@/_modules/promo/api/promo-page.api';
import { TContact } from '@/_types/contact.type';
import EwAvatarCompany from '@/_modules/standalone-company/components/UI/ew-avatar-company/ew-avatar-company.vue';
import iconUnFavorite from '@/_modules/icons/components/icon-ew-unfavorite.vue';
import iconFavorite from '@/_modules/icons/components/icon-ew-favorite.vue';
import iconShare from '@/_modules/icons/components/icon-ew-share.vue';

@Component({
  components: {
    EwAvatarCompany,
    iconUnFavorite,
    iconFavorite,
    iconShare,
  }
})
export default class Company extends mixins(NotificationsMixin) {

  @Action('ewSharerStore/openSharer') openSharer: (payload: TOpenEwSharerPayload) => void;
  @Action('ewSharerStore/closeSharer') closeSharer: () => void;

  @Action('promoPageStore/addFavorite') addFavorite: (payload: TPromoPageFavoriteParams) => Promise<void>;
  @Action('promoPageStore/removeFavorite') removeFavorite: (payload: TPromoPageFavoriteParams) => Promise<void>;

  @Getter('promoPageStore/contact') myself: TContact;

  @Prop(Object) readonly company: TPromoPage;

  @Prop({
    type: String,
    default: 'default',
    validator: (value: string) => {
      return ['default', 'wide'].includes(value);
    }
  })
  public readonly companyType: string;

  @Prop({type: Boolean, default: false})
  public readonly disableOnlineBadge: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly disableUnreadCount: boolean;

  public get isCompanyOwner(): boolean {
    if (!this.company || !this.myself || !this.company.attached_contacts) {
      return false;
    }
    return !!this.company.attached_contacts.find(attachedContact => {
      return attachedContact.role === 'owner' && attachedContact.contact.id === this.myself.id;
    });
  }

  public get photoUrl(): string {
    return this.company && this.company.logo_url;
  }

  public get companyName(): string {
    return this.company && this.company.title;
  }

  public get companyGeoInfo(): string {
    return [this.city, this.country].filter(x => x).join(', ');
  }

  public get companyDescription(): string {
    return this.company && this.company.description;
  }

  public get country(): string {
    return this.company && this.company.country && this.company.country.name;
  }

  public get city(): string {
    return this.company && this.company.city && this.company.city.name;
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public toggleFavorite(): void {
    if (!this.company) {
      return;
    }
    const payload = {
      event_id: this.$route.params.eventId,
      external_id: this.company.external_id
    };
    if (this.company.is_favorite) {
      this.removeFavorite(payload);
    } else {
      this.addFavorite(payload);
    }
  }

  public avatarSize(): number {
    switch (this.companyType) {
      case 'default':
        return 64;
      case 'small':
        return 40;
      case 'calendar':
        return 32;
      default:
        return 64;
    }
  }

  public shareCompany(event: Event): void {
    this.openSharer({
      eventTarget: (event.target as Element),
      url: this.shareUrl(this.company.external_id),
    });
  }

  public shareUrl(externalId: string): string {
    return location.origin + this.$router.resolve({
      name: 'promo-page-events-companies',
      params: {
        eventId: '' + this.eventId,
        externalId: externalId,
      }
    }).href + `${externalId}`;
  }

}
