








import Component from 'vue-class-component';
import { Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'icon-arrow-left',
})
export default class IconArrowLeft extends Vue {
  @Prop({type: String, default: '#000000'}) public readonly fillColor: string;
}
