







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class IconEwStar extends Vue {
  @Prop({ type: Boolean, default: false }) fill: boolean;

  public get isFill(): string {
    return this.fill ? '#02ACEA' : '#001B24';
  }
}
